import React from "react";
import { hydrateRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ensureReady, After } from "@shopdeck/after";
import routes from "./routes";
import "rsuite/dist/rsuite.min.css";
import "./client.scss";
import { App } from "./App";
import * as globals from "../src/services/globals";

// window.__PRELOADED_LOCALS__
export const store = (window.__PRELOADED_LOCALS__ || {});
console.log(store, "store >>>>>>>")

const utmParams = store.utmParams;
const visitorId = store.visitorId;
const sessionId = store.sessionId;
const device = store.device;

globals.set(globals.keys.utmParams, utmParams);
globals.set(globals.keys.visitorId, visitorId);
globals.set(globals.keys.sessionId, sessionId);
globals.set(globals.keys.device, device);

function renderApp() {
  console.log("SERVING ON NEW WEBSITE")
  ensureReady(routes).then((data) => {
    const rootElement = document.getElementById("root");
    if (rootElement) {
      hydrateRoot(
        rootElement,
        <BrowserRouter>
          <App>
            <After transitionBehavior='instant' customParams={{ locals: store }} data={data} routes={routes} />
          </App>
        </BrowserRouter>
      );
    } else {
      console.error("Root element not found");
    }
  });
}

renderApp();

if (module.hot) {
  module.hot.accept("./routes", renderApp);
}
