import React from "react";
import { asyncComponent } from "@shopdeck/after";

export default [
  {
    path: "/registration",
    exact: true,
    Component: asyncComponent({
      loader: () => import(/* webpackChunkName: "SellerRegistration" */ "./pages/SellerRegistration"),
      Placeholder: () => <div>...LOADING...</div>,
      chunkName: "SellerRegistration"
    }),
  },
  {
    path: "/success",
    exact: true,
    Component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Success" */ "./pages/Success"),
      Placeholder: () => <div>...LOADING...</div>,
      chunkName: "Success"
    }),
  },
  {
    path: "/careers",
    exact: true,
    Component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Careers" */ "./pages/Careers"),
      Placeholder: () => <div>...LOADING...</div>,
      chunkName: "Careers"
    }),
  },
  {
    path: "/terms",
    exact: true,
    Component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Terms" */ "./pages/Terms"),
      Placeholder: () => <div>...LOADING...</div>,
      chunkName: "Terms"
    }),
  },
  {
    path: "/privacyPolicy",
    exact: true,
    Component: asyncComponent({
      loader: () => import(/* webpackChunkName: "PrivacyPolicy" */ "./pages/PrivacyPolicy"),
      Placeholder: () => <div>...LOADING...</div>,
      chunkName: "PrivacyPolicy"
    }),
  },
  {
    path: "/privacy-policy",
    exact: true,
    Component: asyncComponent({
      loader: () => import(/* webpackChunkName: "PrivacyPolicy" */ "./pages/PrivacyPolicy"),
      Placeholder: () => <div>...LOADING...</div>,
      chunkName: "PrivacyPolicy"
    }),
  },
  {
    path: "/",
    exact: true,
    Component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Home" */ "./pages/Home"),
      Placeholder: () => <div>...LOADING...</div>,
      chunkName: "Home"
    }),
  },
  {
    path: "/book-demo-form",
    exact: true,
    Component: asyncComponent({
      loader: () => import(/* webpackChunkName: "BookDemoForm" */ "./pages/BookDemoForm"),
      Placeholder: () => <div>...LOADING...</div>,
      chunkName: "BookDemoForm"
    }),
  },
  {
    path: "/thank-you-for-your-interest",
    exact: true,
    Component: asyncComponent({
      loader: () => import(/* webpackChunkName: "ThankYou" */ "./pages/ThankYou"),
      Placeholder: () => <div>...LOADING...</div>,
      chunkName: "ThankYou"
    }),
  },
];
