import React from "react";
import { firePageLoadEvent } from "./events/pageLoad";
import usePageView from "./hooks/usePageView";

export const App = ({ children }) => {
	const { previousLocation } = usePageView();

	React.useEffect(() => {
		firePageLoadEvent();
	}, [previousLocation]);
	return (
		<div>
			{children}
		</div>
	);
};
