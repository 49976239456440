import { push } from "../services/sendEvent";

export const firePageLoadEvent = () => {
	// Call push function with event-label "page_load" and required payload
	push({
		event_label: "page_load",
		event_datum: {},
		visitor_id: "",
		session_id: "",
		language: "",
		useragent: "",
		urlslug: "",
		platform: ""
	});
}
